import axios from "axios"

const baseUrl = "/permission";

const getPermissions = (token, page = 1, keyword = null) => {
  let url = baseUrl;

  if (page !== 1) {
    url = url + '?page=' + page;
  }
  
  if (keyword !== null) {
    url = url + '?keyword=' + keyword;
  }

  return axios.get(url, {
    headers: {
      Authorization: token
    }
  });
}

const updatePermissionRepository = (id, name, token) => {
  let url = baseUrl;
  url = url + "/" + id;

  let data  = {
    '_method': "put",
    name: name
  }

  return axios.post(url, data, {
    headers: {
      Authorization: token
    }
  });
}

const storePermissionRepository = (name, token) => {
  let url = baseUrl;

  let data = {
    name: name
  }

  return axios.post(url, data, {
    headers: {
      Authorization: token
    }
  });
}

const deletePermissionRepository = (id, token) => {
  let url = baseUrl;
  url = url + "/" + id;

  let data = {
    '_method': "delete"
  }

  return axios.post(url, data, {
    headers: {
      Authorization: token
    }
  });
}

export { getPermissions, updatePermissionRepository, storePermissionRepository, deletePermissionRepository };