// import { * } from 'flowbite-react';
// import { Badge, Sidebar } from 'flowbite-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  ChartPieIcon,
  UserGroupIcon
} from '@heroicons/react/24/solid'
import ItemSidebar from '../components/sidebar/ItemSidebar';


function Menu() {
  // let path = window.location.pathname.split('/');
  // path = path[1];

  return (
    <div className={'sidebar'}>
      <div className = "border-2 p-4 text-center" >
        <NavLink exact to="/" className="text-2xl font-bold">BALE WARGA 14</NavLink>
      </div>
      <div className="menu mt-8">
        <div className="w-full">
          <aside className="w-full" aria-label="Sidebar">
            <div className="overflow-y-auto py-4 px-3 bg-white rounded dark:bg-gray-800">
              <ul className="space-y-2">
                <li className="text-xs pl-2 mb-4">Menu Utama</li>
                <li>
                  {/* khusus ini tidak bisa pake component karna harus pake exact */}
                  <NavLink exact to="/" 
                    activeClassName='bg-blue-200'
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700">
                    <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-blue-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <ChartPieIcon></ChartPieIcon>
                    </svg>
                    <span className="ml-3">Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <ItemSidebar
                    href={"/warga"}
                    title="Warga"
                  >
                    <UserGroupIcon></UserGroupIcon>
                  </ItemSidebar>
                </li>
                <li className="text-xs mt-8 pl-2 mb-4">Roles & Permission</li>
                <li>
                  <ItemSidebar
                    href={"/permission"}
                    title="Permission"
                  >
                    <UserGroupIcon></UserGroupIcon>
                  </ItemSidebar>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}

export default Menu;