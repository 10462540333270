import React from 'react';

function Index(props) {
  return (
    <div>
      <h1 className="text-2xl font-bold uppercase">Data Warga</h1>
      <span className="text-small text-slate-600">Menampilkan Seluruh Data Warga</span>
    </div>
  )
}

export default Index;