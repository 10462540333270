import axios from 'axios';
import { Button, Card, Checkbox, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { setCookie } from '../../services/Cookie';
import { authUser } from '../../store';

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [auth, setAuth] = useRecoilState(authUser)

  const MySwal = withReactContent(Swal)
  const submitHandler = (e) => {
    e.preventDefault();

    axios.post('/auth/login', {
      email: email,
      password: password
    }).then(res => {
      MySwal.fire({
        icon: 'success',
        title: 'Login Success',
        text: 'Selamat Datang Kembali ' + res.data.user.name
      })

      setCookie('auth-check', true);
      setCookie('auth-name', res.data.user.name);
      setCookie('auth-email', res.data.user.email);
      setCookie('auth-token', res.data.access_token);
      setCookie('auth-roles', res.data.roles[0].name);

      setAuth({
        check: true,
        user: {
          name: res.data.user.name,
          email: res.data.user.email,
          token: res.data.access_token,
          roles: res.data.roles[0].name,
        }
      })

    }).catch(e => {
      MySwal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Username atau Password Salah',
      })
    })
  }


  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <Card className="w-3/12">
        <div className="flex justify-center">
          <img src="logo192.png" alt="" className='h-16' />
        </div>
        <h5 className="text-xl font-bold text-center">Login</h5>
        <form className="flex flex-col gap-4" onSubmit={submitHandler}>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="Your email"
              />
            </div>
            <TextInput
              id="email1"
              type="text"
              placeholder="masukan email"
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="password1"
                value="Your password"
              />
            </div>
            <TextInput
              id="password1"
              placeholder='masukan password'
              type="password"
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <Checkbox id="remember" />
            <Label htmlFor="remember">
              Remember me
            </Label>
          </div>
          <Button type="submit">
            Submit
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default Login;