import { Pagination, Table } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deletePermissionRepository, getPermissions, storePermissionRepository, updatePermissionRepository } from '../../repositories/Permission';
import { authUser } from '../../store';

function Permission(props) {
  const [permissions, setPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);

  const auth = useRecoilValue(authUser);
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const token = `Bearer ` + auth.user.token;

  const myFunction = async () => {
    getPermissions(token)
      .then(items => {
        setCurrentPage(items.data.data.current_page);
        setTotalPage(items.data.data.last_page);
        setFrom(items.data.data.from);
        setTo(items.data.data.to);
        setTotal(items.data.data.total);
        setPermissions(items.data.data.data);
        
      }).catch(e => {
        
        if (e.code === "ERR_NETWORK") {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.message
          })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.response.status,
            text: e.response.data.message,
          })

          if (e.response.status === 401) {
            history.push('/logout');
          }
        }
      })
  }

  const onPageChange = (e, keyword = null) => {
    setCurrentPage(e)
    getPermissions(token, e, keyword)
      .then(items => {
        setCurrentPage(items.data.data.current_page);
        setTotalPage(items.data.data.last_page);
        setPermissions(items.data.data.data);
        setFrom(items.data.data.from);
        setTo(items.data.data.to);
        setTotal(items.data.data.total);
      })
  }

  useEffect(() => {

    myFunction()
      
  }, [])

  const updatePermission = (id) => {
    let value = document.querySelector('[data-id="' + id + '"]').value;

    if (value === "" || value === null) {
      MySwal.fire({
        icon: 'error',
        title: 'Text Input masih kosong'
      })
    } 
    
    updatePermissionRepository(id, value, token)
      .then(res => {
        MySwal.fire({
          icon: 'success',
          title: 'Success update data'
        })
        onPageChange(currentPage);
        document.querySelector('[data-id="' + id + '"]').value = ""
      }).catch(e => {
        if (e.code === "ERR_NETWORK") {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.message
          })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.response.status,
            text: e.response.data.message,
          })

          if (e.response.status === 401) {
            history.push('/logout');
          }
        }
      });
  }

  const storePermission = () => {
    let value = document.querySelector('[name="newPermission"]').value;

    if (value === "" || value === null) {
      MySwal.fire({
        icon: 'error',
        title: 'Text Input masih kosong'
      })
    }

    storePermissionRepository(value, token)
      .then(res => {
        MySwal.fire({
          icon: 'success',
          title: 'Success insert new data'
        })
        onPageChange(currentPage);
        document.querySelector('[name="newPermission"]').value = ""
      }).catch(e => {
        if (e.code === "ERR_NETWORK") {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.message
          })
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error ' + e.response.status,
            text: e.response.data.message,
          })

          if (e.response.status === 401) {
            history.push('/logout');
          }
        }
      });
  }

  const deletePermission = (id) => {
    Swal.fire({
      title: 'Anda yakin?',
      text: "Data ini akan berpengaruh terhadap user permission",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Lanjutkan'
    }).then((result) => {
      if (result.isConfirmed) {
        deletePermissionRepository(id, token)
          .then(res => {
            MySwal.fire({
              icon: 'success',
              title: 'Success delete data'
            })
            onPageChange(currentPage);
            
          }).catch(e => {
            if (e.code === "ERR_NETWORK") {
              MySwal.fire({
                icon: 'error',
                title: 'Error ' + e.message
              })
            } else {
              MySwal.fire({
                icon: 'error',
                title: 'Error ' + e.response.status,
                text: e.response.data.message,
              })
              if (e.response.status === 401) {
                history.push('/logout');
              }
            }
          });
      }
    })
  }

  return (
    <div>
      <div className="text-2xl font-bold">Permission</div>
      <small className="text-small">Menampilkan daftar permission</small>
      <div className="container py-2 my-4">
        <div className="flex justify-between mb-4">
          <div className="form-group">
            <input type="text" name="newPermission" id="inputNewPermission" placeholder='Input permission Baru' className='border-blue-500 rounded p-2' />
            <button className='bg-blue-700 p-2 px-4 text-white rounded ml-2' onClick={(e) => {storePermission()}}>Submit Data Baru</button>
          </div>
          <div className="form-group">
            <label htmlFor="">Cari :</label>
            <input type="text" name="" id="" placeholder='ketik kata kunci' className='border-blue-500 ml-2 rounded p-2'
              onChange={(e) => onPageChange(currentPage, e.target.value)}
            />
          </div>
        </div>
        <Table hoverable={true}>
          <Table.Head>
            <Table.HeadCell>
              #
            </Table.HeadCell>
            <Table.HeadCell>
              Permission
            </Table.HeadCell>
            <Table.HeadCell>
              Ubah Nama
            </Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only">
                Edit
              </span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {
              permissions.map((permission, index )=> (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={"row-"+index}>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {index+1}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {permission.name}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <input type="text" name={"name"} className="rounded border-blue-500 input-name" placeholder='ubah nama' id="" data-id={permission.id} />
                  </Table.Cell>
                  <Table.Cell>
                    <button className='bg-blue-700 p-2 px-4 text-white rounded' onClick={(e) => {updatePermission(permission.id)}} >Ubah</button>
                    <button className='bg-red-700 p-2 px-4 text-white rounded ml-2' onClick={(e) => {deletePermission(permission.id)}} >Hapus</button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
            
          </Table.Body>
        </Table>
        <div className="flex justify-between items-center">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPage}
            onPageChange={onPageChange}
          />
          <div className='mr-3'>
            Show <span className="fw-bold">{from}</span> - <span className="fw-bold">{to}</span> from <span className="fw-bold">{ total }</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Permission;