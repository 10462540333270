import React from 'react';
import { useParams } from 'react-router-dom';

function Show(props) {
  let { identifier } = useParams();
  
  return (
    <div>
      Ini Halaman Show {identifier}
    </div>
  )
}

export default Show;