import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRecoilValue } from 'recoil';
import { authUser } from '../store';

function Authenticated(props) {
  const history = useHistory()
  const auth = useRecoilValue(authUser);

  useEffect(() => {
    if (!auth.check) {
      history.push('/login');
    }
  }, [])

  return props.children
}

export default Authenticated;