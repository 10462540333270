import React from 'react';

function About(props) {
  return (
    <div>
      <div className="text-4xl">About</div>
    </div>
  )
}

export default About;