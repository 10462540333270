import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter} from 'react-router-dom/cjs/react-router-dom';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { RecoilRoot } from 'recoil';

axios.defaults.baseURL = 'http://dev.balewarga14.com/api';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RecoilRoot>
      <Suspense fallback={<div>Loading ...</div>}>
        <BrowserRouter>
          <App />
        </BrowserRouter> 
      </Suspense>
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
