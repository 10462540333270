import React from 'react';
import { NavLink } from 'react-router-dom';
import Router from '../router';
import Navbar from './Navbar';
import Sidebar from './Menu';

function AppLayout({children}) {
  return (
    <div>
      <div className="grid grid-cols-6 bg-slate-200 min-h-screen">
        <div className="p-4 bg-slate-50">
          <Sidebar></Sidebar>
        </div>
        <div className="col-span-5">
          <div className="p-2 px-4 bg-white border">
            <Navbar></Navbar>
          </div>
          <main className='border p-8'>
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}

export default AppLayout;