import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRecoilValue } from 'recoil';
import { authUser } from '../store';

function Guest(props) {
  const history = useHistory()
  const auth = useRecoilValue(authUser);

  if (auth.check) {
    history.push('/');
  }
  
  return props.children
}

export default Guest;