import React from 'react';

function NotFound(props) {
  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <div className="text-4xl uppercase font-bold">Page Not FOund</div>
    </div>
  )
}

export default NotFound;