import './App.css';
import Router from './router';
import { Link } from 'react-router-dom';
import AppLayout from './layout/AppLayout';

function App() {
  return (
    
    <div className="">
      {/* <AppLayout></AppLayout> */}
      <Router></Router>
    </div>
  );
}

export default App;
