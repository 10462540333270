import React from 'react';
import { NavLink } from 'react-router-dom';

function ItemSidebar(props) {
  return (
    <NavLink to={props.href} 
      activeClassName='bg-blue-200'
      className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700">
      <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-blue-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        {props.children}
      </svg>
      <span className="ml-3">{props.title}</span>
    </NavLink>
  )
}

export default ItemSidebar;