import { atom } from "recoil";
import { getCookie } from "../services/Cookie";

const authUser = atom({
  key: 'authenticated-user',
  default: {
    check: getCookie('auth-check') === null ? false : true,
    user: {
      name: getCookie('auth-name'),
      email:getCookie('auth-email'),
      roles: getCookie('auth-roles'),
      token: getCookie('auth-token')
    }
  }
})

export {authUser}