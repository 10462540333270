import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { eraseCookie } from '../../services/Cookie';
import { authUser } from '../../store';

function Logout(props) {
  const [auth, setAuth] = useRecoilState(authUser)
  const MySwal = withReactContent(Swal)
  const history = useHistory()

  useEffect(() => {
    eraseCookie('auth-check');
    eraseCookie('auth-name');
    eraseCookie('auth-email');
    eraseCookie('auth-token');
    eraseCookie('auth-roles');

    setAuth({
      check: false,
      user: {
        name: null,
        email: null,
        token: null,
        roles: null,
      }
    })

    MySwal.fire({
      icon: 'success',
      title: 'Logout Success'
    })

    history.push('/login')
  }, [])
  
}

export default Logout;