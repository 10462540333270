import { Dropdown } from 'flowbite-react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { authUser } from '../store';
import { UserIcon, CogIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { NavLink } from 'react-router-dom';

function Navbar() {

  const auth = useRecoilValue(authUser)

  return (
    <div className='navbar'>
      <div className="flex justify-between items-center">
        <div className='w-4/12'>
          <input type="text" className="border p-2 px-4 w-full rounded-lg border-slate-400" placeholder='Cari Sesuatu?'/>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded border border-slate-600 mr-8"></div>
          {auth.check ? 
            // <div className="flex items-center">
            //   <div className="w-10 h-10 bg-slate-600 rounded-full"></div>
            //   <div className="mx-2">{ auth.user.name }</div>
            // </div>
            <Dropdown label={auth.user.name} >
              <Dropdown.Header>
                <span className="block text-sm">
                  {auth.user.name}
                </span>
                <span className="block text-sm font-medium truncate">
                  {auth.user.email}
                </span>
              </Dropdown.Header>
              <Dropdown.Item icon={UserIcon}>
                Profil
              </Dropdown.Item>
              <Dropdown.Item icon={CogIcon}>
                Settings
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item icon={ArrowsPointingOutIcon}>
                <NavLink to="/logout">Sign Out</NavLink>
              </Dropdown.Item>
            </Dropdown>
            : 
            <div className="mx-2">Login</div>
            
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar;