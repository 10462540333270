import React from 'react';

function Home(props) {
  return (
    <div>
      <div className="text-4xl">HOME</div>
    </div>
  )
}

export default Home;