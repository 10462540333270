import React from 'react';

import { Switch, Route } from 'react-router-dom';
import NotFound from '../errors/NotFound';
import AppLayout from '../layout/AppLayout';
import Authenticated from '../middleware/Authenticated';
import Guest from '../middleware/Guest';
import { eraseCookie } from '../services/Cookie';
import About from '../views/About';
import Login from '../views/auth/Login';
import Logout from '../views/auth/Logout';
import Home from '../views/Home';
import Permission from '../views/Permission';
import IndexWarga from '../views/Warga/Index';
import ShowWarga from '../views/Warga/Show';

function Router(props) {
  return (
    
    <Switch>
      <Route exact path={"/"}>
        <Authenticated>
          <AppLayout>
            <Home/>
          </AppLayout>
        </Authenticated>
      </Route>
      <Route path={"/about"}>
        <Authenticated>
          <AppLayout>
            <About/>
          </AppLayout>
        </Authenticated>
      </Route>

      {/* warga */}
      <Route exact path={"/warga"}>
        <Authenticated>
          <AppLayout>
            <IndexWarga></IndexWarga>
          </AppLayout>
        </Authenticated>
      </Route>
      <Route exact path={"/warga/:identifier"}>
        <Authenticated>
          <AppLayout>
            <ShowWarga></ShowWarga>
          </AppLayout>
        </Authenticated>
      </Route>

      {/* permission */}
      <Route path={"/permission"}>
        <Authenticated>
          <AppLayout>
            <Permission></Permission>
          </AppLayout>
        </Authenticated>
      </Route>

      {/* auth */}
      <Route path={'/login'} >
        <Guest>
          <Login></Login>
        </Guest>
      </Route>
      <Route path="/logout" component={Logout} />
      
      <Route path="*" component={NotFound}></Route>
    </Switch>    
  )
}

export default Router;